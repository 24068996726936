@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/animate.css/animate.min.css';

@font-face {
  font-family: confortaa-regular;
  src: url('assets/fonts/Comfortaa_Regular.ttf');
}

@font-face {
  font-family: confortaa-bold;
  src: url('assets/fonts/Comfortaa_Bold.ttf');
}

@font-face {
  font-family: confortaa-thin;
  src: url('assets/fonts/Comfortaa_Thin.ttf');
}

body {
  margin: 0;
  font-family: confortaa-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
  color: rgba(0,0,0,0.5);
  /*background-image: url("./assets/images/flowersbg.png");*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-container {
  /*width: 70%;*/
  position: relative;
  color: white;
  margin: auto;
  box-shadow: 0px 5px 5px grey;
}

h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  width: 100%;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  min-height: 700px;
  overflow: hidden;
  background-size: cover;
  background-attachment: fixed;
}
@media (max-width: 1150px) {
  .each-fade .image-container {
    width: 100%;
    min-height: 300px;
    overflow: hidden;
    background-size: contain;
    background-position: top;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .slide-container {
    box-shadow: none;
  }
}

.each-fade .image-container-1 {
  background-image: url("./assets/images/drums2.png");
}

.each-fade .image-container-2 {
  background-image: url("./assets/images/drums3.png");
}

.each-fade .image-container-3 {
  background-image: url("./assets/images/moto1.png");
}

.each-fade .image-container-4 {
  background-image: url("./assets/images/moto2.png");
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.MuiPaper-rounded {
  border-radius: 25px !important;
}